import eCommerceApi from 'ecommerce-module/api/eCommerceApi'
import { eCommerceRefreshTokenID, eCommerceTokenID } from 'ecommerce-module/core/eCommerce.selectors'
import { SET_CAMERA_PLANS } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.actions'

export const SET_PRODUCTS = 'SET_PRODUCTS'

const setProducts = products => ({ type: SET_PRODUCTS, products })
const setPlans = plans => ({ type: SET_CAMERA_PLANS, plans })
const authenticateUser = () => eCommerceApi
  .authenticate()
  .then(data => {
    localStorage.setItem(eCommerceTokenID, data.access_token)
    localStorage.setItem(eCommerceRefreshTokenID, data.refresh_token)
  })
  .catch(error => error)

const fetchProducts = () => dispatch => eCommerceApi.fetchProducts()
  .then(plans => dispatch(setProducts({ plans: plans })))
  .catch(error => error)
const fetchPlans = () => dispatch => eCommerceApi.fetchPlans()
  .then(plans => dispatch(setPlans({ plans: plans })))
  .catch(error => error)

const createBaskets = () => eCommerceApi
  .createBasket()
  .then(data => data)
  .catch(error => error)

const eCommerceActions = { authenticateUser, fetchProducts, setPlans, fetchPlans, setProducts, createBaskets }
export default eCommerceActions
