import useTheme from '@material-ui/core/styles/useTheme'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import BigCheckIcon from 'assets/icons/navigation/BigCheckIcon'
import ClubMembershipMenuItem from 'spypoint/src/shared-module/navigation/menu/items/ClubMembershipMenuItem'
import UserIcon from 'assets/icons/navigation/UserIcon'
import ReferralIcon from 'assets/icons/navigation/ReferralIcon'
import SpypointHelpMenuItem from 'spypoint/src/shared-module/navigation/menu/items/SpypointHelpMenuItem'
import LogoutMenuItem from 'shared-module/navigation/menu/items/LogoutMenuItem'
import LinkMenuItem from 'shared-module/navigation/menu/items/LinkMenuItem'
import HdPackageMenuItem from 'shared-module/navigation/menu/items/HdPackageMenuItem'
import MenuHeader from 'shared-module/navigation/menu/items/MenuHeader'
import CameraMenuItems from 'shared-module/navigation/menu/camera-items/CameraMenuItems'
import SpypointLogo from 'spypoint/src/assets/icons/SpypointLogo'
import MenuDivider from 'shared-module/navigation/menu/items/MenuDivider'
import HdVideoPackageMenuItem from 'shared-module/navigation/menu/items/HdVideoPackageMenuItem'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import CameraSharing from 'assets/icons/navigation/CameraSharing'
import CloudIcon from '@mui/icons-material/Cloud'
import { useECommerceProductsActions } from 'ecommerce-module/core/eCommerce.hook'
import { useEffect } from 'react'

const Menu = ({ open, onClose }) => {
  const theme = useTheme()
  const usingECommerce = appConfig.eCommerce.switch === switches.on
  const shared = JSON.parse(localStorage.getItem('isShared'))
  const { getPlans } = useECommerceProductsActions()

  useEffect(() => {
    if (usingECommerce) {
      getPlans()
    }
  }, [usingECommerce, getPlans])
  return (
    <Drawer open={open} onClose={onClose} PaperProps={{ style: { background: theme.palette.background.default, width: 350 } }}>
      <MenuHeader onClick={onClose} Logo={SpypointLogo} />
      <CameraMenuItems onClose={onClose} />
      <MenuDivider />
      <List>
        <LinkMenuItem Icon={BigCheckIcon} text="menu.select_camera" onClick={onClose} path="/" />
      </List>
      <MenuDivider />
      <List>
        <ClubMembershipMenuItem onClick={onClose} />
        <HdPackageMenuItem onClick={onClose} />
        { !shared && <HdVideoPackageMenuItem onClick={onClose} /> }
        { appConfig.sharedCamera?.switch === switches.on && <LinkMenuItem Icon={CameraSharing} text="menu.share_camera" onClick={onClose} path="/camera-sharing/guests" /> }
        <LinkMenuItem Icon={UserIcon} text="app:menu.account" onClick={onClose} path="/account/profile" />
        { usingECommerce && <LinkMenuItem Icon={CloudIcon} text="E-commerce Test" onClick={onClose} path="/e-commerce/products" /> }
      </List>
      <MenuDivider />
      <List>
        <LinkMenuItem Icon={ReferralIcon} text="menu.referral_program" onClick={onClose} path="/promo-codes" />
        <SpypointHelpMenuItem onClick={onClose} />
      </List>
      <MenuDivider />
      <List>
        <LogoutMenuItem onClick={onClose} />
      </List>
    </Drawer>
  )
}

export default Menu
