import restapi from 'shared-module/api/restapi'
import commerceSelector, { eCommerceTokenID } from 'ecommerce-module/core/eCommerce.selectors'
import eCommerceBuilder from 'ecommerce-module/api/eCommerce.builder'

const authenticate = () => restapi
  .post('/api/v3/commerce/user/token')
  .then(response => response.data)
  .catch(error => error)

const retryRequest = async (requestFn, retries = 3) => {
  try {
    return await requestFn()
  } catch (error) {
    if (retries > 0) {
      console.warn(`Retrying request... Attempts left: ${retries}`)
      return retryRequest(requestFn, retries - 1)
    } else {
      console.error('Failed to complete request after multiple attempts:', error)
      throw error
    }
  }
}

const fetchInsidersClubProduct = () => restapi
  .get(
    `/api/v3/commerce/products?ids=${commerceSelector.salesForceInsidersClub}`, {
      headers: { 'x-commerce-token': localStorage.getItem(eCommerceTokenID) },
    })
  .then(response => response.data.data)
  .catch(error => error)

const fetchPlans = () => retryRequest(() =>
  restapi.get(
    `/api/v3/commerce/products?ids=${commerceSelector.salesForcePlansIds}`, {
      headers: { 'x-commerce-token': localStorage.getItem(eCommerceTokenID) },
    })
    .then(response => response.data.data.map(plan => eCommerceBuilder.buildECommercePlans(plan))),
)

const fetchProducts = (retries = 3) => retryRequest(() =>
  restapi.get(
    '/api/v3/commerce/products?ids=D06121,D06131,D06141,D06120,D06130,D06140', {
      headers: { 'x-commerce-token': localStorage.getItem(eCommerceTokenID) },
    })
    .then(response => response.data.data.map(addon => eCommerceBuilder.buildECommercePlans(addon)))
, retries)

const createBasket = () => retryRequest(() =>
  restapi.post(
    '/api/v3/commerce/baskets', {
      headers: { 'x-commerce-token': localStorage.getItem(eCommerceTokenID) },
    })
    .then(response => response.data.data.map(basket => eCommerceBuilder.buildBaskets(basket))),
)

const getBasket = (basketId) => retryRequest(() =>
  restapi.get(`/api/v3/commerce/baskets/${basketId}`, {
    headers: { 'x-commerce-token': localStorage.getItem(eCommerceTokenID) },
  })
    .then(response => response.data),
)

const addItemToBasket = (basketId, itemDataList) => {
  const token = localStorage.getItem(eCommerceTokenID)

  const requestBody = {
    item_list: itemDataList.map(item => ({
      itemId: item.itemId,
      productId: item.productId,
      quantity: item.quantity || 1,
    })),
  }

  return retryRequest(() =>
    restapi.post(
      `/api/v3/commerce/baskets/${basketId}/items`,
      requestBody,
      {
        headers: { 'x-commerce-token': token },
      },
    )
      .then(response => response.data),
  )
}

const updateBasketItem = (basketId, itemDataList) => {
  const token = localStorage.getItem(eCommerceTokenID)

  const requestBody = {
    item_list: itemDataList.map(item => ({
      itemId: item.itemId,
      product_id: item.productId,
      quantity: item.quantity,
    })),
  }

  return retryRequest(() =>
    restapi.patch(
      `/api/v3/commerce/baskets/${basketId}/items`,
      requestBody,
      {
        headers: { 'x-commerce-token': token },
      },
    )
      .then(response => response.data),
  )
}

const eCommerceApi = { authenticate, fetchPlans, fetchProducts, createBasket, getBasket, addItemToBasket, updateBasketItem, fetchInsidersClubProduct }
export default eCommerceApi
